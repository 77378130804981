import $http from "./index";
import qs from "qs";

import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

/** 同步大区小区数据 */
export const syncShopRegion = (data) => {
  return $http.get(`/app/info/shop/back/SyncShop `, {
    params: data,
  });
};
